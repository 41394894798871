import { IconList } from "../elements/Icon/IconList"

export const MainNavigation = () => {
    return (
        <div className="p-6 flex space-x-3">
            <div className="w-6 h-6">
                <IconList />
            </div>
            <button>Websites</button>
        </div>
    )
}