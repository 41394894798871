export const SearchInput = (props) => {
  return (
    <input
      onChange={(e) => props.onSearch(e.target.value)}
      type="search"
      placeholder="Search for a website..."
      className="bg-gray-800 outline-none text-sm md:text-base text-gray-300 p-3 rounded-xl w-full sm:w-64"
    />
  );
};
