import { CardWebsite } from "../../elements/Card/CardWebsite";
import { CardSkeleton } from "../../elements/Skeleton/CardSkeleton";

export const LayoutGrid = (props) => {
  const { items = [], loading } = props;
  return (
    <div className="grid xl:grid-cols-2 gap-2 xl:gap-4 xl:my-4 my-2">
      {loading
        ? [...Array(20)].map((item, index) => <CardSkeleton key={index} />)
        : items.map((item, index) => (
            <CardWebsite loading={loading} data={item} key={index} />
          ))}
    </div>
  );
};
