import { BadgeSimple } from "../elements/Badge/BadgeSimple"

export const ToolBar = (props) => {
    const { data = [], loading } = props

    const countRecords = (records, key, value) =>
        records.filter(record => record[key] === value).length

    const filterHealthy = (healthy) =>
        props.filterByHealth(healthy);

    const filterInfluenceEngine = (onboarded) =>
        props.filterInfluenceEngine(onboarded);

    return (
        <div className="flex w-full md:px-5 py-4 justify-between">
            <div className="space-x-2 flex items-center">
                <span className="font-semibold hidden sm:flex">{loading ? 'Loading' : data.length} Websites</span>
                <BadgeSimple clickable={true} clicked={() => filterHealthy(true)} status={1} label={loading ? '-' : countRecords(data, "healthy", true)} />
                <BadgeSimple clickable={true} clicked={() => filterHealthy(false)} status={2} label={loading ? '-' : countRecords(data, "healthy", false)} />
                <BadgeSimple clickable={true} clicked={() => filterInfluenceEngine(true)} status={0} label={loading ? '-' : `${countRecords(data, "InfluenceEngineOnBoarded", true)} IE Sites`} />
                <BadgeSimple clickable={true} clicked={() => filterInfluenceEngine(false)} status={0} label={loading ? '-' : `${countRecords(data, "InfluenceEngineOnBoarded", false)} Legacy Sites`} />
                <BadgeSimple clickable={true} clicked={() => props.clearFilters()} status={0} label={loading ? '-' : 'X Clear'} />
            </div>
        </div>
    )
}