import { IconLoadingSpinner } from "../Icon/IconLoadingSpinner"

export const CardSkeleton = () => {
    return ( 
        <div className="w-full bg-white rounded-xl p-3 md:p-4 flex flex-col sm:justify-between shadow-sm hover:shadow-xl transition-all duration-200 ease-in-out cursor-pointer hover:bg-gray-100">
            <div className="sm:flex sm:space-x-3 sm:items-center">
                <div className="w-full sm:w-auto">
                    <div className="bg-gray-200 h-8 w-56 rounded-lg animate-pulse"></div>
                </div>
                <div className="bg-gray-200 rounded-lg text-xs uppercase p-1 px-2 w-10 my-1 flex justify-center">
                    <IconLoadingSpinner />
                </div>
            </div>
        </div>
    )
}