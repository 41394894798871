import { BadgeSimple } from "../Badge/BadgeSimple";
import { LinkExternal } from "../Link/LinkExternal";
import { IconWordPress } from "../Icon/IconWordPress";
export const CardWebsite = (props) => {
  const {
    domain = "",
    healthy = true,
    msg = "",
    InfluenceEngineOnBoarded = false,
    lastChecked,
  } = props.data;
  const lastCheckedDate = new Date(lastChecked).toLocaleString(["en-AU"], {
    timeZone: "Australia/Melbourne",
  });
  return (
    <div className="w-full bg-white rounded-xl p-3 md:p-4 flex flex-col sm:justify-between shadow-sm hover:shadow-xl transition-all duration-200 ease-in-out cursor-pointer hover:bg-gray-100">
      <div
        className={`sm:flex sm:space-x-3 sm:items-center ${
          props.loading ? "bg-gray-300 h-4" : ""
        }`}
      >
        <div className="w-full sm:w-auto">
          <LinkExternal host={domain} />
        </div>
        <div className="w-full sm:w-auto flex space-x-2 my-1 flex-wrap">
          <BadgeSimple label={msg} status={healthy ? 1 : 2} />
          <BadgeSimple
            label={InfluenceEngineOnBoarded ? "IE Site" : "Legacy"}
            status={0}
          />
        </div>
      </div>
      <div className="text-xs sm:text-sm text-gray-700 w-full">
        {lastCheckedDate}
      </div>
    </div>
  );
};
