import { AmplifySignOut } from "@aws-amplify/ui-react";
import { LogoHeader } from "../elements/Logo/LogoHeader";
import { SearchInput } from "../search/SearchInput";

export const TopBar = (props) => (
  <div className="w-full flex top-0 fixed z-20">
    <div className="w-80 bg-gray-400 hidden sm:flex">
      <LogoHeader headline="MadHouse Dashboard" />
    </div>
    <div className="w-full bg-gray-600 flex justify-between p-2 md:p-6 items-center">
      <SearchInput onSearch={(text) => props.onSearch(text)} />
      <div className="hidden sm:block">
        <AmplifySignOut />
      </div>
    </div>
  </div>
);
