import { stringReplace } from "../../../utilities/stringReplace"

const hostdisplay = (host) => {
    let display = host
    display = stringReplace(display, "www.", "")
    display = display.slice(0, -1)
    return display
}

const link = (host) => `https://${host.slice(0, -1)}`

export const LinkExternal = (props) => (
  <a
    className="hover:underline text-sm sm:text-base cursor-pointer"
    href={link(props.host)}
    target="_blank"
    rel="noreferrer"
  >
    {hostdisplay(props.host)}
  </a>
);
