export const BadgeSimple = (props) => {
  const { label = "", status = 1, clickable = false } = props;

  const statusClass = (statusCode = 1) => {
    switch (statusCode) {
      case 0:
        return "bg-gray-300";
      case 1:
        return "bg-green-300 text-green-900";
      case 2:
        return "bg-red-300 text-red-900";
      default:
        return "bg-gray-300";
    }
  };
  return (
    <div
      onClick={() => clickable && props.clicked()}
      className={`${statusClass(
        status
      )} rounded-lg text-xs md:text-xs p-1 px-2 w-auto cursor-pointer hover:bg-opacity-80 transition-all ease-in-out`}
    >
      {label}
    </div>
  );
};
