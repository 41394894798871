import React, { useEffect, useState } from "react";
import "./index.css";
import Amplify, { API } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { LayoutGrid } from "./components/layout/Grid/LayoutGrid";
import { MainNavigation } from "./components/navigation/MainNavigation";
import { TopBar } from "./components/navigation/TopBar";
import { ToolBar } from "./components/navigation/ToolBar";
import Fuse from "fuse.js";
import { stringReplace } from "./utilities/stringReplace";
Amplify.configure(awsconfig);

const App = () => {
  const [websites, setWebsites] = useState([]);
  const [filteredWebsites, setFilteredWebsites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterHeath, setFilterHealth] = useState(null);
  const [filterInfluenceEngine, setFilterInfluenceEngine] = useState(null);
  const [searchText, setSearchText] = useState("");

  const getData = async () => {
    return API.get('websiteChecks','/items')
    .then(response => {
      setWebsites(response.Items)
      setFilteredWebsites(response.Items)
    })
    .then(() => setLoading(false))
    .catch(err => { console.error(err); setLoading(false);})  
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const sorted = websites.sort((a, b) =>
      stringReplace(a.domain, "www.", "").localeCompare(
        stringReplace(b.domain, "www.", "")
      )
    );
    setFilteredWebsites(sorted);
    return () => {};
  }, [websites]);

  useEffect(() => {
    setFilteredWebsites(
      websites.filter((website) => website.healthy === filterHeath)
    );
  }, [filterHeath]);

  useEffect(() => {
    
     setFilteredWebsites(
          websites.filter(
            (website) =>
              website.InfluenceEngineOnBoarded === filterInfluenceEngine
          )
        )
    
  }, [filterInfluenceEngine]);

  useEffect(() => {
    const fuse = new Fuse(websites, {
      includeScore: true,
      threshold: 0.1,
      keys: ["domain"],
    });

    const results = fuse
      .search(searchText)
      .map((result) => websites[result.refIndex]);
    setFilteredWebsites(searchText ? results : websites);
  }, [searchText]);

  return (
    <>
      <TopBar onSearch={(text) => setSearchText(text)} />
      <div className="min-h-screen w-full flex">
        <div className="w-80 bg-gray-100 hidden md:flex flex-col pt-32 sticky top-0 h-screen">
          <MainNavigation />
        </div>
        <div className="w-full bg-gray-200 pt-14 mt-2 md:pt-28">
          <ToolBar
            filterByHealth={(val) => setFilterHealth(val)}
            filterInfluenceEngine={(val) => setFilterInfluenceEngine(val)}
            clearFilters={() => setFilteredWebsites(websites)}
            data={websites}
            loading={loading}
          />
          <div className="px-2 md:px-4">
            <LayoutGrid
              items={filteredWebsites.filter((website) => !website.healthy)}
              loading={loading}
            />
            <LayoutGrid
              items={filteredWebsites.filter((website) => website.healthy)}
              loading={loading}
            />
            {!loading && filteredWebsites.length === 0 && (
              <div className="py-6 italic">
                Sorry, there aren't any websites that match your search input.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticator(App);
